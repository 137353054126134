.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.player {
  width: 240px;
  height: 180px;
  margin: 50px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  -webkit-transform: scaleX(var(--flip));
  transform: scaleX(var(--flip));
  --flip: 1;
}

.p2 {
  --flip: -1;
}

.player-image {
  height: 250px;
  width: auto;
}

.playBtn {
  width: 100px;
  height: 50px;
  display: inline;
  margin: auto 15px;
  color: #fff;
  font-size: 18px;
  border-radius: 25px;
  background: #000;
}

.bounce {
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
  -moz-animation: bounce 1s infinite;
  -o-animation: bounce 1s infinite;
}

/* @-webkit-keyframes bounce { */
/*   0%, 20%, 50%, 80%, 100% { */
/*     -webkit-transform: translateY(0 * var(--flip)); */
/*   } */
/*   40% { */
/*     -webkit-transform: translateY(-30px * var(--flip)); */
/*   } */
/*   60% { */
/*     -webkit-transform: translateY(-15px * var(--flip)); */
/*   } */
/* } */
/* @-moz-keyframes bounce { */
/*   0%, 20%, 50%, 80%, 100% { */
/*     -moz-transform: translateY(0); */
/*   } */
/*   40% { */
/*     -moz-transform: translateY(-30px); */
/*   } */
/*   60% { */
/*     -moz-transform: translateY(-15px); */
/*   } */
/* } */
/* @-o-keyframes bounce { */
/*   0%, 20%, 50%, 80%, 100% { */
/*     -o-transform: translateY(0); */
/*   } */
/*   40% { */
/*     -o-transform: translateY(-30px); */
/*   } */
/*   60% { */
/*     -o-transform: translateY(-15px); */
/*   } */
/* } */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
    
/* @-webkit-keyframes bounce { */
  /* 0%       { bottom:5px; } */
  /* 25%, 75% { bottom:15px; } */
  /* 50%      { bottom:20px; } */
  /* 100%     { bottom:0; } */
/* } */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
